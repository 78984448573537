const SVGGradients = () => {
    return (
      <svg width="0" height="0">
        <linearGradient
          id="codecosmos-gradient"
          x1="100%"
          y1="100%"
          x2="0%"
          y2="0%"
        >
          <stop stopColor="#fb3eff" offset="0%" />
          <stop stopColor="#0bedf2" offset="100%" />
        </linearGradient>
      </svg>
    );
  };
  
  export default SVGGradients;
  
import Button from "./Button";
import {
  SparklesIcon,
  PaperAirplaneIcon,
  UserIcon
} from "@heroicons/react/outline";
import SVGGradients from "./SVGGradients";
import { ReactComponent as Logo } from "./assets/codecosmos.svg";

function App() {
  return (
    <div className="App">
      <SVGGradients />

      <header className="h-10 bg-red-200 flex items-center justify-center p-5 py-24 lg:py-36">
        <div className="flex flex-wrap absolute top-0 left-0 p-5 justify-between items-center w-full">
          <div className="flex flex-wrap">
            <Logo className="m-auto pb-3 cursor-pointer text-white h-16 pt-2 pr-2 my-2" />
            <p className="m-auto p-auto w-auto text-white font-medium tracking-wide-2 cursor-pointer lg:text-lg">
              CODEFEVER
            </p>
          </div>
          <div>
          <Button
            className="mb-4 lg:mb-0 md:mr-4 uppercase inline-block"
            icon={UserIcon}
            iconPlacement="left"
            disabled={false}
            isInverted={true}
            href="https://store.codecosmos.com/api/auth/sign-in?lang=nl&post_login_page=courses"
          >
            Login
          </Button>
          </div>
        </div>

        <div className="text-white max-w-6xl">
          <h1 className=" text-3xl md:text-5xl lg:text-6xl uppercase font-weight-600 text-white font-bold mb-10">
          Zet het festival thuis verder en maak kans op gratis
            <span className="gradient-text"> codeerlespakketten </span>
          </h1>

          <Button
            className="mb-4 lg:mb-0 md:mr-4 uppercase inline-block"
            icon={SparklesIcon}
            iconPlacement="right"
            disabled={false}
            isInverted={true}
            href="#start"
          >
            Thuis verder programmeren
          </Button>
        </div>

        <div className="absolute bottom-5 right-5 flex items-center">
          <img src="/minecraft.png" alt="" className="w-36 md:w-48 mr-4" />
          <img src="/nerdland.png" alt="" className="w-36 md:w-48" />
        </div>
      </header>

      <div className="px-8 py-10 md:px-20 md:py-10">
        <div className="max-w-6xl m-auto md:flex">
          <div className="hidden md:w-2/5 md:block">
            <div className="bg-white p-2 shadow-md rounded-sm -rotate-2 relative -top-16 -left-8 z-10">
              <img src="/nerdlandaffiche.png" alt="" className="polaroid" />
            </div>
          </div>
          <div className="md:w-3/5">
            <h2 className="mb-8 uppercase font-bold text-2xl tracking-wide leading-6">
              Had jij het ook zo naar je zin op het
              <span className="gradient-text"> Nerdland Festival?</span>
            </h2>

            <p className="mb-2 text-gray-500">
              Top! Hier kan je het feestje gewoon verderzetten:
            </p>

            <p className="mb-2 text-gray-500">
              Zo kan je de Nerdland Minecraft-wereld, die je op het festival
              leerde kennen, opnieuw ontdekken EN kan je ook een gratis
              CodeFever lessenreeks winnen.
            </p>
          </div>
        </div>
      </div>

      <div className="px-8 py-10 md:px-20 md:py-10 bg-gray-100" id="start">
        <div className="max-w-6xl m-auto md:flex">
          <div className="md:w-3/5">
            <h2 className="mb-8 uppercase font-bold text-2xl tracking-wide leading-6">
              Klaar om te verder te{" "}
              <span className="gradient-text">programmeren</span> in de wereld?
            </h2>

            <p className="mb-2 text-gray-700 font-semibold">
              Maak een account aan door alle volgende stappen te doorlopen:
            </p>

            <ul className="text-gray-600 list-disc ml-4">
              <li className="mb-4">
                <p className="mb-4">
                  Klik op de knop “Programeer verder” om een account aan te
                  maken. Vul je gegevens in.
                </p>
                <Button
                  className="mb-4 md:mb-0 md:mr-4 uppercase inline-block"
                  icon={PaperAirplaneIcon}
                  iconPlacement="right"
                  disabled={false}
                  target="_blank"
                  href="https://store.codecosmos.com/api/auth/sign-in?lang=nl&accountType=CODECOSMOS_PARENT&post_login_page=courses&utm_source=nerdland&signup=true"
                >
                  Programeer verder
                </Button>
                <br />
                <p className="mt-2 text-sm text-gray-500">
                  Heb je al een account op codecosmos? Zie onderaan.
                </p>
              </li>

              <li className="mb-4">
                Je bent nu op het tabblad lesmateriaal. Klik verder op Nerdland
                uitdagingen, onder de titel Demo lesmateriaal, om verder te gaan
                met de festivaluitdaging.
              </li>

              <li className="mb-4">
                Open de presentatie ‘Aan de slag met Minecraft:EE’ als je meer
                info wil over het downloaden, aanmelden en je voortbewegen in
                Minecraft Education Edition.
              </li>

              <li className="mb-4">
                Open de presentatie ‘Nerdland uitdagingen’ om de
                Minecraft-wereld te downloaden en te importeren. In die les
                krijg je uitleg over de uitdaging zelf.
              </li>

              <li className="mb-4">
                Open Minecraft Education Edition en importeer de Nerdlandwereld.
                Heb je geen inloggegevens meer van Minecraft:EE? Geen nood! Je
                ontvangt automatisch nieuwe inloggegevens in je mailbox voor 10
                keer gratis inloggen (check je spam folder!).
              </li>
            </ul>

            <p className="mt-8 mb-16 text-sm text-gray-500">
              <strong>Let op:</strong> Wanneer je al een CodeCosmos-account
              hebt, zal je mailtje moeten sturen naar info@codecosmos.com om je
              toegang te geven tot de demo. (Of je maakt gewoon een nieuw
              account aan.)
            </p>
          </div>

          <div className="hidden md:w-2/5 md:block">
            <div className="bg-white p-2 shadow-md rounded-sm rotate-3 relative -top-16 -right-8">
              <img src="/pc1.jpg" alt="" className="polaroid" />
            </div>
          </div>
        </div>
      </div>

      <div className="px-8 py-10 md:px-20 md:py-10">
        <div className="max-w-6xl m-auto md:flex">
          <div className="hidden md:w-2/5 md:block">
            <div className="bg-white p-2 shadow-md rounded-sm -rotate-3 relative -top-16 -left-8 z-10">
              <img src="/minecraftee.webp" alt="" className="polaroid" />
            </div>
          </div>
          <div className="md:w-3/5">
            <h2 className="mb-8 uppercase font-bold text-2xl tracking-wide">
              Ik wil nog meer{" "}
              <span className="gradient-text">programmeren!</span>
            </h2>

            <p className="mb-2 text-gray-500">
              Begint het al te kriebelen om thuis verder aan de slag te gaan met
              meer coding-materiaal? Met het account dat je hebt aangemaakt in
              de vorige stappen, heb je ook toegang tot een Minecraft
              Education-coding-les of Scratch-coding-les voor beginners.
            </p>

            <p className="mb-2 text-gray-500">
              Klik op de Lesmateriaal tab en ontdek wat je allemaal kan vinden
              onder Demo Lesmateriaal!
            </p>
          </div>
        </div>
      </div>

      <div className="px-8 py-10 md:px-20 md:py-10 text-gray-600 bg-gray-100">
        <div className="max-w-6xl m-auto md:flex">
          <div className="hidden md:w-2/5 md:block">
            <div className="bg-white p-2 shadow-md rounded-sm rotate-3 relative top-16 -left-8 z-10">
              <img src="/codefever.jpeg" alt="" className="polaroid" />
            </div>
          </div>
          <div className="md:w-3/5">
            <h2 className="mb-8 uppercase font-bold text-xl tracking-wide leading-6">
              <span className="gradient-text">CodeFever</span> in een notendop
            </h2>

            <ul className="list-disc ml-4">
              <li className="mb-4">
                Begeleide fysieke lessen in kleine groepen met een hoge fun
                factor
              </li>

              <li className="mb-4">
                Een educatief onderbouwde lesmethode met uniek lespakket
              </li>

              <li className="mb-4">
                Een doordacht vervolgtraject op semesterbasis (Scratch,
                Minecraft Education, JavaScript, Python, ...)
              </li>

              <li className="mb-4">
                Toegang tot het online CodeFever-leerplatform
              </li>

              <li className="mb-4">Professionele lesgevers uit de IT-wereld</li>

              <li className="mb-4">
                Een groepsgebeuren: je kind leert met andere kinderen eigen
                spelletjes programmeren
              </li>

              <li className="mb-4">
                De lessenreeksen starten in september en/of februari
              </li>
            </ul>

            <p className="mb-4">
              Meer weten? Surf naar {" "}
              <a
                className="animated-underline"
                href="https://www.codefever.be/"
              >
                codefever.be
              </a>
              !
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gray-800 p-8 text-center text-gray-300 text-sm">
        © CodeFever 2022 -{" "}
        <a
          target="_blank"
          href="/wedstrijdreglement.pdf"
          className="animated-underline"
        >
          Wedstrijdreglement
        </a>
      </div>
    </div>
  );
}

export default App;

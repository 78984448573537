import classNames from 'classnames';

const Element = ({ children, ...props }) => {
  if (props.href) {
    return <a {...props}>{children}</a>;
  } else {
    return <button {...props}>{children}</button>;
  }
};

const Button = (props) => {
  const {
    center,
    className,
    children,
    icon,
    iconPlacement = 'right',
    disabled,
    isInverted = false,
    size = 'md',
    ...rest
  } = props;
  const IconSymbol = icon;
  return (
    <Element
      {...rest}
      className={classNames(
        'block w-auto shadow-sm rounded-full ',
        className,
        { 'bg-black': !isInverted, 'bg-white': isInverted },
        { 'cursor-not-allowed opacity-50': disabled },
        { 'hover-gradient-bg': !disabled },
      )}
      style={{ padding: '2px', borderStyle: 'none', borderWidth: 'medium' }}
    >
      <div
        className={classNames(
          'py-3 px-8 flex text-md  rounded-full font-semibold tracking-widest',
          { relative: icon },
          { 'text-black': !isInverted, 'text-white': isInverted },
          { 'py-3 px-8': size === 'md', 'py-1 px-3': size === 'sm' },
          { 'bg-black': isInverted, 'bg-white': !isInverted },
          { 'icon-hover-gradient': icon && !disabled },
          { 'pr-14': icon && iconPlacement === 'right' },
          { 'pl-14': icon && iconPlacement === 'left' },
          { 'place-content-center	': center },
        )}
      >
        {children}

        {icon && (
          <IconSymbol
            className={classNames(
              'inline absolute top-2 w-8 h-8',
              { 'right-3': iconPlacement === 'right' },
              { 'left-3': iconPlacement === 'left' },
            )}
          />
        )}
      </div>
    </Element>
  );
};

export default Button;
